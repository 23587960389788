import { ThemeProvider, loadStyleThemes } from "@tm/context-distribution"
import { OAuthTokenIds } from "@tm/models"
import { createBrowserHistory } from "history"
import { render } from "react-dom"
import { Route, Router, Switch } from "react-router"
import { getCatalogTexts } from "../data/translations"
import { initMock } from "../mocks"
import { loadBaseConfig } from "./data/loadBaseConfig"
import { loadConfiguration } from "./data/loadConfiguration"
import { loadSprites } from "./data/loadSprites"
import { loadStyles } from "./data/loadStyles"
import { resetLoadProgress } from "./data/loadingProgress"
import { getSavedCatalog } from "./document/currentCatalogStorage"
import { getLanguageId } from "./localization/getLanguageId"
import { getLocalizationProvider } from "./localization/getLocalizationProvider"
import { initLogging } from "./logging/initLogging"
import { OAuthComponent } from "./oauth/OAuthComponent"
import { getStylesUrl } from "./theming/getStylesUrl"
import { getMuiThemeUrl, getThemeUrl } from "./theming/getThemeUrl"
import { useHasConcept } from "./theming/useHasConcept"

export const SimplePortalRoutes = [
    "/oauth",
]

export async function initializeSimplePortal() {
    initLogging()
    initMock()

    const appConfig = await loadConfiguration(await loadBaseConfig(), "APP", getSavedCatalog(), location.hostname, undefined)

    if (!appConfig) {
        return
    }

    const hasConcept = useHasConcept(appConfig)

    loadStyles(getStylesUrl(appConfig.styles, hasConcept))
    loadSprites(appConfig.icons)

    await loadStyleThemes(getThemeUrl(appConfig.styles, hasConcept), getMuiThemeUrl(appConfig.styles, hasConcept))

    const LocalizationProvider = await getLocalizationProvider(
        getLanguageId(appConfig, undefined),
        appConfig,
        getCatalogTexts.bind(undefined, appConfig.params.translationsServiceUrl, appConfig.login.authId)
    )

    resetLoadProgress()

    render((
        <ThemeProvider>
            <LocalizationProvider>
                <Router history={createBrowserHistory()}>
                    <Switch>
                        <Route path="/oauth/:tokenId" render={(props) => <OAuthComponent tokenId={props.match.params.tokenId as OAuthTokenIds} config={appConfig.params.oAuthConfiguration} isProd={appConfig.params.env === "prod"} />} />
                    </Switch>
                </Router>
            </LocalizationProvider>
        </ThemeProvider>
    ), document.querySelector("#app"))
}
